import React from "react";
import Card from "../components/card";
import ClosestStation from "../components/closestStation";
import FiveDaysForecast from "../components/fiveDaysForecast";
// Components -------------------------------------------------
import IPMCropTools from "../components/ipmCropTools";
import Layout from "../components/layout";
import Map from "../components/map";
import PromoBanner from "../components/promoBanner";
import Seo from "../components/seo";
import StationsDropdownV2 from "../components/stationsDropdownV2";
import WeatherStationOverview from "../components/weatherStationOverview";
import WeatherTools from "../components/weatherTools";
import GlobalStateContext from "../context/globalStateContext";
import useFetchStationData from "../utils/hooks/useFetchStationData";
import useStations from "../utils/hooks/useStations";

const IndexPage = React.memo(() => {
  const { stationList, station, setStation, geoJSON } = useStations();
  const { isSearch } = React.useContext(GlobalStateContext);

  const sdate = `${new Date().getFullYear()}010100`;
  const edate = "now";
  const { isLoading, data, isError } = useFetchStationData(
    station,
    sdate,
    edate
  );
  const h1Text = "NEWA | Network for Environment and Weather Applications";

  return (
    <Layout station={station} h1Text={h1Text} useAuth0DefaultRedirect={true}>
      <Seo title="Home" />
      <section
        id="home-weather"
        aria-label="Weather Information"
        className="text-center"
      >
        <div>
          <h2 className="p-2 mb-12 text-xl font-semibold tracking-wider text-white md:p-4 lg:p-6 sm:text-2xl md:text-3xl bg-primary-700">
            Weather and science driven integrated pest management tools
          </h2>
          <h2 className="px-2 mt-6 text-2xl font-bold leading-none tracking-wider sm:text-3xl md:text-4xl">
            IPM Forecasts and Weather Data
            <div className="px-2 mt-3 text-base font-light text-gray-800 md:text-2xl">
              Get started by selecting your nearest weather station.
            </div>
          </h2>

          <div className="px-4 mt-6 sm:mt-8 md:mt-12">
            <div className="flex flex-col items-center">
              <div className="z-20 w-3/4 mx-auto my-4 text-center sm:w-96">
                Select or search by weather station
                <StationsDropdownV2
                  options={stationList}
                  value={station}
                  setValue={setStation}
                ></StationsDropdownV2>
              </div>
            </div>
            {station && <ClosestStation closestStations={[]}></ClosestStation>}
          </div>

          <div className="px-4 mt-12 sm:mx-auto sm:max-w-screen-2xl sm:px-6 lg:px-8">
            <div className={`grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-12`}>
              <div aria-label="NEWA stations map" className="hidden md:block">
                <div aria-hidden="true">
                  <Card
                    title="Click or tap on a station marker to load its overview"
                    color="primary"
                    map={true}
                  >
                    <Map
                      station={station}
                      setStation={setStation}
                      allStations={stationList}
                      geoJSON={geoJSON}
                    ></Map>
                  </Card>
                </div>
              </div>

              <div>
                <WeatherStationOverview
                  station={station}
                  isLoading={isLoading}
                  isError={isError}
                  data={data}
                  setIsModalOpen={null}
                ></WeatherStationOverview>

                {data && (
                  <div className="mt-8 sm:mt-12">
                    {station !== null && !isSearch && (
                      <FiveDaysForecast
                        isLoading={isLoading}
                        isError={isError}
                        data={data.dailyData.filter((d) => d.isForecast)}
                      ></FiveDaysForecast>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-2 md:gap-12">
              <IPMCropTools></IPMCropTools>
              <WeatherTools></WeatherTools>
            </div>
          </div>
        </div>
      </section>

      {/* Promo Banner section--------------------------------------------------- */}
      <PromoBanner></PromoBanner>
    </Layout>
  );
});

export default IndexPage;
