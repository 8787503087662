import { useAuth0 } from "../context/react-auth0-spa"
import { Link } from "gatsby"
import React from "react"

export default function PromoBanner() {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  return (
    // Promo Banner section -----------------------------------------------------------
    <section
      id="home-promo"
      aria-label="Promotional Message"
      className="mt-8 md:mt-12"
    >
      <div className="relative flex flex-col items-center justify-center w-full pt-4 mt-6 text-white h-72 bg-secondary-800 lg:h-84">
        <div className="container absolute z-20 px-4 text-center">
          <h2 className="mb-4 text-2xl font-bold tracking-wider sm:text-3xl md:text-4xl">
            The Tools I Want, Where I Want.
          </h2>
          <h3 className="px-2 mb-8 text-xl font-light">
            Create a NEWA profile to customize your weather dashboard and store
            biofix details.
          </h3>

          <div className="flex items-center justify-around max-w-sm pb-4 mx-auto">
            <Link
              aria-label="Learn more about NEWA profiles"
              to="/create-dashboard"
              className="mr-1 btn btn-secondary lg:btn-large sm:mr-0"
            >
              Learn More{" "}
              <span className="ml-3">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4 arrow-narrow-right sm:w-6 sm:h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </Link>

            <button
              className="ml-1 btn btn-secondary lg:btn-large sm:ml-0"
              onClick={() => {
                if (!isAuthenticated) {
                  loginWithRedirect({
                    appState: {
                      returnTo: window.location.pathname,
                    }
                  });
                }
              }}
            >
              Go to Profile{" "}
              <span className="ml-3">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4 arrow-narrow-right sm:w-6 sm:h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
